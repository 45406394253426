import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require('bootstrap')

class Login extends Component {
    constructor(props) {
        super();
        this.state = {
            username: '',
            password: ''
        };
    }

    setCookie = (name, value, minutes) => {
        var expires = "";
        if (minutes) {
            var date = new Date();
            date.setTime(date.getTime() + (minutes * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
        login = (e) => {
            e.preventDefault();
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: ''
            };
            
            fetch("Admin/Login?Username=" + this.state.username + "&Password=" + this.state.password, requestOptions).then(x => x.text()).then(x => {
               // toast(x);
                if (x !== -1) {
                    this.setCookie("_log", x, 15);
                    this.props.login();
                } else {
                    toast('Не правильный логин или пароль.');
                }
            });

        }

        render() {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <ToastContainer />

                    <div className="login-block">
                        <form onSubmit={(e) => this.login(e)}>
                            <input onInput={(e) => this.setState({ username: e.target.value })} className="form-control" type="text" placeholder="Логин" />
                            <br />
                            <input onInput={(e) => this.setState({ password: e.target.value })} className="form-control" type="password" placeholder="Пароль" />
                            <br />
                            <button onClick={(e) => this.login(e)} className="btn btn-primary w-100">Войти</button>
                        </form>

                    </div>

                </div>
            );
        }

    }
    export default Login;


