import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
require('bootstrap')

class Infos extends Component {
    constructor() {
        super();
        this.state = {
            arr: []
        };
    }

    componentDidMount = () => {
        fetch('Admin/GetInfos').then(x => x.json()).then(x => this.setState({ arr: x }));
    }






    render() {
        return (
            <div className="">

                <ToastContainer />
                {this.state.arr.map(x =>
                    <div style={{ width: '600px', padding: '5px', border: '1px solid black', borderRadius: '4px', marginBottom: '5px' }}>
                        <p><span style={{ color: 'red' }}>Id:</span> {x.id}</p>
                        <p><span style={{ color: 'red' }}>Детали:</span> <a target="_blank" href={x.details}>{x.details}</a></p>
                        <p><span style={{ color: 'red' }}>Сайт:</span> <a target="_blank" href={x.site}>{x.site}</a></p>
                        <p><span style={{ color: 'red' }}>Правила англ:</span> <p dangerouslySetInnerHTML={{ __html: x.rulesEn }}></p></p>
                        <p><span style={{ color: 'red' }}>Правила рус:</span> <p dangerouslySetInnerHTML={{ __html: x.rulesRu }}></p></p>
                        <p><span style={{ color: 'red' }}>Правила укр:</span> <p dangerouslySetInnerHTML={{ __html: x.rulesUa }}></p></p>
                    </div>
                )}
            </div>
        );
    }

}
export default Infos;


