import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
require('bootstrap')

class Reservs extends Component {
    constructor() {
        super();
        this.state = {
            reservs: [],
            modal: false,
            selectedCert: -1,
            newReserv: ''
        };
    }

    componentDidMount = () => {
        this.fetchReservs();
    }


    fetchReservs = () => {
        fetch('Admin/GetReservs').then(x => x.json()).then(x => this.setState({ reservs: x }))
    }

    getCertName = (id) => {
        var certs = [
            ["9", "Новус"],
            ["10", "Комфі"],
            ["11", "Фокстрот"],
            ["19", "Епіцентр"],
            ["44","Эльдорадо 500"],
            ["79", "Бомонд"],
            ["80", "Сільпо"],
            ["187", "Алло"],
            ["245", "ОККО 500"],
            ["258", "Планета кіно"],
            ["265", "Пандора 500"],
            ["281", "Якабу"],
            ["288","Аптека"],
            ["300", "Андер Армор"],
            ["345", "Концерт Юа"],
            ["346", "Локаль"],
            ["396", "Золотий Вік 500"],
            ["459", "Гастрофемілі"],
            ["301", "МД Фешн 500"],
            ["490", "Мультиплекс"],
            ["140", "Вайнтайм 500"],
            ["10001", "Ля Фамілія"],
            ["10002", "Флорум"],
            ["10003", "Спортлайф"],
            ["10004", "Репортер"],
            ["10005", "Артист"],
            ["10006", "Коаст"],
            ["10007", "Будинок Іграшок"],
            ["10008", "Унмоменто"],
            ["10009","Такси"],
            ["10010", "Планета кино 100 x5"],
            ["10011", "Comfy на 100"],
            ["10012", "Comfy на 200"],
            ["10013", "Епіцентр на 100"],
            ["10014", "Епіцентр на 250"],
            ["10015", "Алло на 100"],
            ["10016", "АШАН на200"],
            ["10017", "Novus на 100"],
            ["10018", "Novus на 200"],
            ["10019", "Планета Кино на 200"],
            ["10020", "Watson на 100"],
            ["10021", "Watson на 250"],
            ["10023", "WOG на 100"],
            ["10024", "WOG на 200"],
            ["10025", "Планета кино 100"],
            ["10026", "Giftmall 100"],
            ["10027", "Giftmall 200"],
            ["10028", "Giftmall 300"],
            ["10029", "Giftmall 400"],
            ["10030", "Giftmall 500"],
            ["10033", "Будинок іграшок 100"],
            ["10034", "Будинок іграшок 250"],
            ["10035", "Yakaboo 200"],
            ["10036", "Yakaboo 300"],
            ["10229", "Eva 100"],
            ["10031", "JYSK 100"],
            ["10032", "JYSK 250"],
            ["10037", "Аптека 100"],
            ["10038", "Аптека 200"],
            ["10039", "Вайнтайм 250"],
            ["228", "Eva 300"]
        ];
        for (var i = 0; i < certs.length; i++) {
            if (parseInt(certs[i][0]) === id) {
                return certs[i][1];
            }
        }
    }
    saveReserv = () => {
        var n_this = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: '"' + this.state.selectedCert + ';' + this.state.newReserv + '"'
        };
        fetch("Admin/ChangeReserv", requestOptions).then(x => { n_this.fetchReservs(); n_this.setState({ modal: false }); toast('Готово.') });
        
    }
    closeModal = () => {
        this.setState({modal: false});
    }
    render() {
        return (
            <div className="">
                <div style={{ display: this.state.modal ? 'block' : 'none' }} className="modal" >
                    <div className="modal-dialog modal-dialog-scrollable"  >
                        <div className="modal-content">
                            <div className="modal-header">
                              
                                <button type="button" onClick={() => this.closeModal()} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <input onInput={(e) => this.setState({ newReserv: e.target.value })} />
                                <button onClick={() => this.saveReserv()} className="btn btn-secondary">Сохранить</button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => this.closeModal()} className="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
                <table className="table">
                    <tr>
                        <th>
                            Id
                        </th>
                        <th>
                            Сертификат
                        </th>
                        <th>
                            Тип
                        </th>
                        <th>
                            Резерв
                        </th>
                        <th>
                            В наличии
                        </th>
                    </tr>
                    {this.state.reservs.map(x =>
                        <tr>
                            <td>{x.certType}</td>
                            <td>{this.getCertName(x.certType)}</td>
                            <td>{x.uploadType === 1 ? 'АПИ' : "Ручная загрузка"}</td>
                            <td>{x.nReserve}</td>
                            <td>{x.inStock}</td>
                            <td><button onClick={() => this.setState({ modal: true, selectedCert: x.id })} className="btn btn-primary">Изменить</button></td>
                        </tr>
                        )}
                    
                </table>
            </div>
        );
    }

}
export default Reservs;


