import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
require('bootstrap')

class Emails extends Component {
    constructor(props) {
        super();
        this.state = {
            EmailLogs: '',
            ErrorsEmailsLogs: ''
        };
    }

    componentDidMount = () => {
        fetch('Front/GetEmailLogs').then(x => x.text()).then(x => this.setState({ EmailLogs: x }));
        fetch('Front/GetErrorsEmailsLogs').then(x => x.text()).then(x => this.setState({ ErrorsEmailsLogs: x }));
    }

    saveEmail = () => {
        if (this.state.EmailLogs.indexOf('@') === -1) {
            toast('Введите email');
            return;
        }
        fetch('Admin/SetEmailLogs', {
            method: "POST",
            body: `"${this.state.EmailLogs}"`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => {
            if (x.status === 200) {
                toast('Готово');
            } else {
                toast('Ошибка сервера');
            }
        });
    }
    saveEmailErrors = () => {
        if (this.state.ErrorsEmailsLogs.indexOf('@') === -1) {
            toast('Введите email');
            return;
        }
        fetch('Admin/SetErrorsEmailsLogs', {
            method: "POST",
            body: `"${this.state.ErrorsEmailsLogs}"`,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(x => {
            if (x.status === 200) {
                toast('Готово');
            } else {
                toast('Ошибка сервера');
            }
        });
    }
    render() {
        return (
            <div >
                <ToastContainer />
                <p>Почта для уведомлений про заканчивающееся сертификаты</p>
                <input defaultValue={this.state.EmailLogs} onInput={(e) => this.setState({ EmailLogs: e.target.value })} type='text' />
                <button className="btn btn-primary" onClick={this.saveEmail}>Сохранить</button>
                <br />
                <p>Почта для уведомлений про ошибки</p>
                <input defaultValue={this.state.ErrorsEmailsLogs} onInput={(e) => this.setState({ ErrorsEmailsLogs: e.target.value })} type='text' />
                <button className="btn btn-primary" onClick={this.saveEmailErrors}>Сохранить</button>
            </div>
        );
    }

}
export default Emails;


