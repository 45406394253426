import React, { Component } from 'react';
import { Route } from 'react-router';
import Login from './components/Login.js';
import Main from './Main.js';
import { ToastContainer, toast } from 'react-toastify';

import './main.css';
require('bootstrap')

class App extends Component {
    constructor() {
        super();
        this.state = {
            logined: false
        };
    }

    componentDidMount = () => {
      //  toast(window.location.href);
    }

    login = () => {
        this.setState({ logined: true });
    }
    getRightBlock = () => {
        if (window.location.href.indexOf('DetailStatistic') !== -1) {
           // return;
        } else {
            return this.state.logined ? <Main /> : <Login login={this.login} />;
        }
    }
    render() {
        return (
            <div>
                <ToastContainer />
                {this.getRightBlock()}
            </div>
        );
    }

}
export default App;

/*
 
 */

/*


                <button className="btn">maska</button>
                <div>
                    <p>Файл до <span style={{ color: "red" }}>7 Мбайт</span></p>
                    <input onChange={this.readFile} type="file" />
                </div>
                <div>
                    <p>Баланс: {balance}</p>
                </div>
                <ul>
                    {preview.map(x =>
                        <li>{x}</li>)}
                </ul>
 */