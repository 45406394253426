import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
require('bootstrap')

class ProjectSetup extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            selectedCerts: [],
            selectedModerators: [],
            certificates: [
                ["1", "Юникредит"],
                ["5", "BROCARD"],
                ["8", "ZARINA"],
                ["9", "Novus"],
                ["10", "Comfy"],
                ["11", "Foxtrot"],
                ["19", "Эпицентр"],
                ["22", "deko"],
                ["44", "Эльдорадо"],
                ["49", "Спортмастер"],
                ["56", "musthave"],
                ["71", "Watsons"],
                ["77", "secunda"],
                ["79", "bomond"],
                ["80", "Сильпо"],
                ["140", "Winetime"],
                ["156", "butlers"],
                ["162", "argo"],
                ["175", "zabros"],
                ["185", "Auchan"],
                ["187", "Алло"],
                ["202", "cmik"],
                ["207", "furshet"],
                ["222", "apteka_watsons"],
                ["228", "Eva"],
                ["229", "intertop"],
                ["230", "marc_o_polo"],
                ["231", "geox"],
                ["232", "ecco"],
                ["233", "napapijri"],
                ["234", "clarks"],
                ["235", "skechers"],
                ["237", "timberland"],
                ["238", "kosmo"],
                ["239", "prostor"],
                ["245", "okko_95"],
                ["251", "jysk"],
                ["252", "Goodwine"],
                ["254", "antoshka"],
                ["255", "skazka_nova"],
                ["256", "makeup"],
                ["258", "planetakino"],
                ["263", "armani-exchange"],
                ["265", "pandora"],
                ["266", "Levis"],
                ["267", "lagerfeld"],
                ["268", "guess"],
                ["269", "baldinini"],
                ["270", "superdry"],
                ["271", "hugo_boss"],
                ["276", "miraton"],
                ["279", "synevo"],
                ["281", "Yakaboo"],
                ["282", "orner"],
                ["284", "brabrabra"],
                ["287", "persha_socialna"],
                ["288", "apteka_dobrogo_dnya"],
                ["290", "oodji"],
                ["291", "walker"],
                ["292", "stem"],
                ["293", "accessorize"],
                ["294", "gant"],
                ["295", "g_star_raw"],
                ["296", "monsoon"],
                ["297", "tommy_hilfiger"],
                ["298", "diesel"],
                ["299", "pepe_jeans"],
                ["300", "under_armour"],
                ["302", "birka"],
                ["303", "berghoff"],
                ["304", "parfums"],
                ["305", "yastreb"],
                ["306", "cote_jeunot"],
                ["307", "g_bar"],
                ["310", "kuz"],
                ["311", "Wog"],
                ["312", "Wog_cafe"],
                ["313", "posuda_ua"],
                ["315", "Фора"],
                ["316", "deka"],
                ["317", "flagman"],
                ["318", "lush"],
                ["319", "Нова Лінія"],
                ["320", "united_colors_of_benetton"],
                ["321", "mango"],
                ["322", "parfois"],
                ["323", "orsay"],
                ["324", "ovs_kids"],
                ["325", "penti"],
                ["326", "desigual"],
                ["327", "lee_cooper"],
                ["328", "argosha"],
                ["329", "liu_jo"],
                ["330", "scotch_soda"],
                ["331", "morza"],
                ["332", "mi_store"],
                ["334", "Citrus"],
                ["336", "sammy_icon"],
                ["337", "journals_ua"],
                ["338", "karabas"],
                ["339", "meiwei"],
                ["340", "hugo"],
                ["341", "esprit"],
                ["345", "concert_ua"],
                ["346", "lokal"],
                ["347", "leboutique"],
                ["348", "sushiya"],
                ["349", "5000_miles"],
                ["350", "intersport"],
                ["351", "drunkcherry"],
                ["352", "rebernya"],
                ["353", "lvivski_pljacky"],
                ["355", "epic"],
                ["356", "megogo"],
                ["357", "piel_cosmetics"],
                ["358", "le_pelerin"],
                ["359", "tarantino"],
                ["360", "murakami"],
                ["361", "rukkola"],
                ["362", "hmeli_suneli"],
                ["363", "baklazhan"],
                ["364", "sindikat"],
                ["365", "bi"],
                ["366", "miy_auchan"],
                ["368", "pravda"],
                ["369", "pid_zolotoju_rozoju"],
                ["370", "gasova_lampa"],
                ["371", "badboy"],
                ["372", "kryjivka"],
                ["373", "naidorozhcha_restoracija_galychyny"],
                ["374", "dim_legend"],
                ["375", "masoch_cafe"],
                ["376", "coffee_manufacture"],
                ["377", "grand_cafe_leopolis"],
                ["378", "dzyga"],
                ["379", "pretty_high_kitchen"],
                ["380", "opera_underground"],
                ["381", "meat_and_justice"],
                ["382", "poshta_na_drukarskiy"],
                ["383", "lviv_chocolate_manufacture"],
                ["384", "diana_na_rynku"],
                ["385", "pstruh_hlib_ta_vyno"],
                ["386", "varus"],
                ["387", "tea_house"],
                ["388", "sova_jewerly"],
                ["389", "tui"],
                ["390", "play_station"],
                ["392", "avocado"],
                ["393", "drommel"],
                ["396", "zolotoy_vek"],
                ["409", "ukrzoloto"],
                ["415", "vans"],
                ["416", "the_north_face"],
                ["417", "aromaburo"],
                ["418", "sertifikat-na-pokhod-v-kino"],
                ["419", "skymallkarting"],
                ["420", "blackwood"],
                ["421", "sertifikat-na-karting"],
                ["422", "divantv"],
                ["423", "eduget"],
                ["424", "sertifikat-na-kurs-myslit-kak-povar"],
                ["425", "papa_john"],
                ["426", "sertifikat-na-upravlencheskiy-kurs"],
                ["427", "khachapuri_wine"],
                ["428", "sertifikat-na-kurs-professionalnogo-biznes-kouchinga"],
                ["429", "sertifikat-na-uskorennyy-kurs-grammatiki-angliyskogo-yazyka"],
                ["430", "marketplaza"],
                ["431", "kompot"],
                ["434", "spezzo"],
                ["436", "yaro"],
                ["438", "englishdom"],
                ["439", "droneua"],
                ["457", "klunok"],
                ["458", "veselka"],
                ["459", "gastrofamily"],
                ["460", "kanapa"],
                ["461", "biliy_naliv"],
                ["462", "pinzarella"],
                ["463", "philadelphia"],
                ["464", "barsuk"],
                ["465", "mushlya"],
                ["466", "dogz_burgerz"],
                ["467", "bpsh"],
                ["468", "lyubchyk"],
                ["470", "kanapka-bar"],
                ["471", "vatra"],
                ["472", "chicken-kyiv"],
                ["473", "oxota-na-ovets"],
                ["474", "gusovsky"],
                ["480", "1_euro_delivery"],
                ["484", "napule"],
                ["485", "osteria_pantagruel"],
                ["486", "shoti"],
                ["487", "ocafe"],
                ["488", "adelle"],
                ["489", "liberty_diner"],
                ["490", "multiplex"]
            ],
            moderators: [],
            certModal: false,
            moderModal: false
        };
    }

    componentDidMount = () => {
        fetch('Admin/GetAllUsers').then(x => x.json()).then(x => this.setState({ moderators: x }));
    }

    save = () => {
        if (this.state.name.length < 3) {
            toast("Дайте имя проекту.");
        } else if (this.state.selectedCerts.length === 0) {
            toast("Выбирите сертификаты.");
        } else {
            this.createProject();
            toast("Готово.");
        }
    }
    addCertificate = (certId) => {
        var arr = this.state.selectedCerts;
        var _arr = [];
        if (arr.indexOf(certId) !== -1) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i] != certId) {
                    _arr.push(arr[i]);
                }
            }
            this.setState({ selectedCerts: _arr });
        } else {
            arr.push(certId);
            this.setState({ selectedCerts: arr });
        }
    }



    closeCertModal = () => {
        this.setState({ certModal: false });
    }
    closeModerModal = () => {
        this.setState({ moderModal: false });
    }
    addModerator = (modId) => {
        var arr = this.state.selectedModerators;
        var _arr = [];
        if (arr.indexOf(modId) !== -1) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i] != modId) {
                    _arr.push(arr[i]);
                }
            }
            this.setState({ selectedModerators: _arr });
        } else {
            arr.push(modId);
            this.setState({ selectedModerators: arr });
        }
    }
    getCertName = (id) => {
        var name = '';
        this.state.certificates.forEach(function (item) {
            if (item[0] === id) {
                name = item[1];
                return;
            }
        });
        return name;
    }
    getModerName = (id) => {
        var name = '';
        this.state.moderators.forEach(function (item) {
            if (item.split(';')[0] === id) {
                name = item.split(';')[1];
                return;
            }
        });
        return name;
    } 

    createProject = () => {
        var json = {
            Creator: {
                Id: window.$id
            },
            Name: this.state.name,
            Moderators: this.state.selectedModerators,
            Certificates: this.state.selectedCerts
        };
        var s_json = JSON.stringify(json);
        s_json = s_json.replace(/"/g, "'");
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
         
            body: '"' + s_json +'"'
        };
        fetch("Admin/CreateProject", requestOptions);
    
    }

    render() {
        return (
            <div className="">
                <ToastContainer />
                
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div style={{ width: '45%' }}>
                        <input onInput={(e) => this.setState({ name: e.target.value })} className="form-control" type="text" placeholder="Название проекта" />
                        <br />
                        <b>Сертификаты: 
                        {this.state.selectedCerts.map(x =>
                            <span>{this.getCertName(x)}, </span> 
                            )}
                            </b>
                        <br/>
                        <button className="btn btn-primary" onClick={() => this.setState({ certModal: true })}>Добавить сертификаты</button>
                        <br/>
                        <div style={{ display: this.state.certModal ? 'block' : 'none' }} className="modal" >
                            <div className="modal-dialog modal-dialog-scrollable"  >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Modal title</h5>
                                        <button type="button" onClick={() => this.closeCertModal()} className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.certificates.map(x =>
                                            <div>
                                                <input value={x[0]} onInput={(e) => this.addCertificate(e.target.value)} type="checkbox" style={{ marginRight: '10px' }} /><span>{x[1]}</span>
                                                <br />
                                            </div>

                                        )}
                                    </div>
                                    <div className="modal-footer"> 
                                        <button type="button" onClick={() => this.closeCertModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <b>Модераторы: 
                        {this.state.selectedModerators.map(x =>
                            <span>{this.getModerName(x)}, </span>
                        )}
                            </b>
                        <br />
                        <button className="btn btn-primary" onClick={() => this.setState({ moderModal: true })}>Добавить модераторов</button>
                        <br />
                        <div style={{ display: this.state.moderModal ? 'block' : 'none' }} className="modal" >
                            <div className="modal-dialog modal-dialog-scrollable"  >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Modal title</h5>
                                        <button type="button" onClick={() => this.closeModerModal()} className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        {this.state.moderators.map(x =>
                                            <div>
                                                <input value={x.split(';')[0]} onInput={(e) => this.addModerator(e.target.value)} type="checkbox" style={{ marginRight: '10px' }} /><span>{x.split(';')[1]}</span>
                                                <br />
                                            </div>

                                        )}
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" onClick={() => this.closeModerModal()} className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <br />
                        <button onClick={() => this.save()} className="btn btn-primary w-100">Создать</button>
                    </div>
                </div>
            </div>
        );
    }

}
export default ProjectSetup;


