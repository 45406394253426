import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import _load from './load.gif';
require('bootstrap')

class Users extends Component {
    constructor() {
        super();
       
        this.state = {
            data: [],
            userData: [],
            userId: -99,
            modal: false
        };
    }
    componentDidMount = () => {
        fetch('Admin/GetUsers').then(x => x.json()).then(x => this.setState({ data: x }));
    }
   
    getCertName = (id) => {
        var certs = [
            ["9", "Новус"],
            ["10", "Комфі"],
            ["11", "Фокстрот"],
            ["19", "Епіцентр"],
            ["44", "Эльдорадо"],
            ["79", "Бомонд"],
            ["80", "Сільпо"],
            ["187", "Алло"],
            ["245", "ОККО"],
            ["258", "Планета кіно"],
            ["265", "Пандора"],
            ["281", "Якабу"],
            ["288", "Аптека"],
            ["300", "Андер Армор"],
            ["345", "Концерт Юа"],
            ["346", "Локаль"],
            ["396", "Золотий Вік"],
            ["459", "Гастрофемілі"],
            ["301", "МД Фешн"],
            ["490", "Мультиплекс"],
            ["140", "Вайнтайм"],
            ["10001", "Ля Фамілія"],
            ["10002", "Флорум"],
            ["10003", "Спортлайф"],
            ["10004", "Репортер"],
            ["10005", "Артист"],
            ["10006", "Коаст"],
            ["10007", "Будинок Іграшок"],
            ["10008", "Унмоменто"],
            ["10009", "Такси"],
            ["10010", "Планета кино 100 x5"],
            ["10011", "Comfy на 100"],
            ["10012", "Comfy на 200"],
            ["10013", "Епіцентр на 100"],
            ["10014", "Епіцентр на 250"],
            ["10015", "Алло на 100"],
            ["10016", "АШАН на200"],
            ["10017", "Novus на 100"],
            ["10018", "Novus на 200"],
            ["10019", "Планета Кино на 200"],
            ["10020", "Watson на 100"],
            ["10021", "Watson на 250"],
            ["10023", "WOG на 100"],
            ["10024", "WOG на 200"],
            ["10025", "Планета кино 100"],
            ["10026", "Giftmall 100"],
            ["10027", "Giftmall 200"],
            ["10028", "Giftmall 300"],
            ["10029", "Giftmall 400"],
            ["10030", "Giftmall 500"],
            ["10031", "Goodwine"],
            ["10229", "Eva 100"],
            ["228", "Eva 300"]
        ];
        for (var i = 0; i < certs.length; i++) {
            if (parseInt(certs[i][0]) === id) {
                return certs[i][1];
            }
        }
    }
    getUserInfo = (id) => {
        fetch('Admin/GetUserInfo?userId=' + id).then(x => x.json()).then(x => this.setState({ modal: true, userData: x, userId: id }));
    }
    closeModal = () => {
        this.setState({ modal: false });
    }
    sendCertificate = (_data) => {
        var data = _data;
        data.userId = this.state.userId;
        data.certName = this.getCertName(_data.type);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch("Admin/SCert", requestOptions).then(x => this.setState({modal: false}));
       
    }
    render() {
        return (
            <div className="">
                <div style={{ display: this.state.modal ? 'block' : 'none' }} className="modal" >
                    <div style={{ maxWidth: '750px' }} className="modal-dialog modal-dialog-scrollable"  >
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" onClick={() => this.closeModal()} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <table className="table">
                                    <tr>
                                        <th>Id</th>
                                        <th>Сертификат</th>
                                        <th>Время</th>
                                        <th></th>
                                    </tr>
                                    {this.state.userData.map(x =>
                                        <tr>
                                            <td>{x.id}</td>
                                            <td>{this.getCertName(x.type)}</td>
                                            <td>{x.time}</td>
                                            <td><button className="btn btn-secondary" onClick={() => this.sendCertificate(x)}>Отправить на почту сертификат</button></td>
                                        </tr>
                                        )}
                                   
                                </table>
                             
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={() => this.closeModal()} className="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table">
                    <tr>
                        <th>UserId</th>
                        <th></th>
                       
                    </tr>
                    {this.state.data.map(x => 
                        <tr>
                            <td>{x}</td>
                            <td><button className="btn btn-secondary" onClick={() => this.getUserInfo(x)}>Посмотреть информацию</button></td>
                           
                        </tr>
                        )}
                </table>
              
            </div>
        );
    }

}
export default Users;


