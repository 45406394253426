import React, { Component } from 'react';
import { Route } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import _load from './load.gif';
require('bootstrap')

class ApiLogs extends Component {
    constructor() {
        super();
        this.codes = {
            101: "Don't have certificate",
            200: "OK",
            201: "Resend OK",
            401: "Security error",
            403: "Project does't exist",
            409: "Certificate don't exist",
            415: "Bad json"
        };
        this.state = {
          data: []
        };
    }
    componentDidMount = () => {
        fetch('Admin/GetLogs').then(x => x.json()).then(x => this.setState({data: x}));
    }
    
   
    render() {
        return (
            <div className="">
                <ToastContainer />
                <table className="table">
                    <tr>
                        <th>Id</th>
                        <th>Имя Сертификата</th>
                        <th>Проект</th>
                        <th>Время</th>
                        <th>Результат</th>
                    </tr>
                    {this.state.data.map(x => 
                        <tr>
                            <td>{x.id}</td>
                            <td>{x.cert_name}</td>
                            <td>{x.project === null ? "null" : x.project.name}</td>
                            <td>{x.t_time}</td>
                            <td>{this.codes[x.result]}</td>
                        </tr>
                        )}
                </table>
              
            </div>
        );
    }

}
export default ApiLogs;


