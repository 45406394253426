import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Route } from 'react-router';
require('bootstrap')


class Admin extends Component {
    constructor(props) {
        super();
        this.state = {
            clicked: false,
            username: '',
            password: '',
            users: [],
            selected: -1,
            changePassword: ''
        };
    }

    fetchUsers = () => {
        fetch('Admin/GetAllUsers').then(x => x.json()).then(x => this.setState({ users: x }));
    }

    componentDidMount = () => {
        this.fetchUsers();
    }
    reg = () => {
        var n_this = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: '"GddKYG678rUKkj86rYGIHJLPUI86rrydihoi6urILIHuo026IYF"'
        };
        fetch("Admin/CreateAccount?Username=" + this.state.username + "&Password=" + this.state.password, requestOptions).then(x => {
            if (x.status === 200) {
                n_this.fetchUsers();
                toast('Создано');
            } else {
                toast('Ошибка');
            }

        });
    }

    changePassword = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: '"GKzKfBIRT6r877do8yliKLUUYuIPp8tuooR6PpIoir7"'
        };
        fetch("Admin/ChangePassword?Id=" + this.state.selected + "&Password=" + this.state.changePassword, requestOptions).then(x => {
            if (x.status === 200) {
              
                toast('Успешно');
            } else {
                toast('Ошибка');
            }

        });
    }

    render() {
        return (
            <div className="">
                <ToastContainer />
                <div style={{ display: 'flex', top: '1em', position: 'relative' }}>
                    <ul style={{ width: '30%', marginRight: '2em' }}>

                        {this.state.users.map(x =>
                            <li onClick={() => this.setState({ clicked: true, selected: x.split(';')[0] })} className="list-group-item btn btn-light">{x.split(';')[1]}</li>
                        )}
                    </ul>
                    <div className="card" style={{ marginRight: '2em' }}>

                        <p style={{ marginLeft: '1em' }}>Создание нового пользователя</p>

                        <div className="card-body" >
                            <input onInput={(e) => this.setState({ username: e.target.value })} className="form-control" type="text" placeholder="Имя пользователя" />
                            <br />
                            <input onInput={(e) => this.setState({ password: e.target.value })} className="form-control" type="password" placeholder="Пароль" />
                            <br />
                            <button onClick={this.reg} className="btn btn-primary">Создать</button>
                        </div>


                    </div>
                    <div className="card" style={{ display: this.state.clicked ? "" : "none" }}>
                        <p style={{ marginLeft: '1em' }}>Изменить пароль</p>
                        <div className="card-body">
                            <input onInput={(e) => this.setState({ changePassword: e.target.value })} className="form-control" type="password" placeholder="Пароль" />
                            <br />
                            <button onClick={this.changePassword} className="btn btn-primary">Сохранить</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Admin;


